import { useState } from 'react';
import { isAndroid, isMobile } from 'react-device-detect';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router';
import {
  IconCloudUpload,
  IconHistory,
  IconPhotoUp,
  IconCheckbox,
  IconUserQuestion,
  IconCoinOff,
  IconBrain,
  IconDevices,
  IconClockBolt,
  IconCamera, IconBrandTiktok,
} from '@tabler/icons-react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { TikTokEmbed } from 'react-social-media-embed';
import googlePlayBadge from '@images/google-play-badge.png';
import iconCheck from '@images/icon-check.png';
import FacebookLike from '@components/FacebookLike';
import Dropzone from '../components/Dropzone';
import SpinnerGrow from '../components/SpinnerGrow';
import TablerIcon from '../components/TablerIcon';
import { useLoginRegister } from '../hooks/templates';
import { useUser } from '../hooks/auth';
import { MotionItem, MotionViewport } from '../components/animations';
import { useCurrentQuota } from '../hooks/account';
import { uploadFile } from '../api/index';
import { useLocalStore, useSettings } from '../hooks';
import BasePage from './layouts/BasePage';

const onUpload = ({
  files,
  setProgress,
  setIsSuccessUpload,
  resetDropzone,
  setProcessing,
  quota,
  queryClient,
  isAuthenticated,
  open,
  navigate,
}) => {
  if (!isAuthenticated) {
    return open();
  }

  if (quota == null) {
    resetDropzone();
    return toast.warning('Brak wykupionego pakietu.');
  }
  if (quota?.pdfs <= 0) {
    resetDropzone();
    return toast.warning('Wykorzystałeś swój limit zadań. Dokup pakiet aby korzystać dalej.');
  }

  uploadFile('user/chat', files[0], {
    onUploadProgress: (e) => {
      const perc = (e.loaded / e.total) * 100;
      setProgress(perc);
      if (perc >= 100) setProcessing(true);
    },
  }).then((res) => {
    setIsSuccessUpload(true);
    if (res.data && !res.data?.errors) {
      const { chatRoom } = res.data;
      queryClient.invalidateQueries('user.quota');

      return navigate(`/playground/${chatRoom.uuid}`);
    }

    toast.error(res.data?.message);
    resetDropzone();
  }).catch((err) => {
    if (err.response?.status === 422 || err.response?.status === 400) {
      toast.warning(err.response?.data?.message);
    } else {
      toast.error(err.message);
    }
    resetDropzone();
  }).finally(() => {
    setProcessing(false);
  });
};

const onError = () => {
  toast.error('Zdjęcie odrzucone.');
};

export default function HomePage({ googleAuthInitialized }) {
  const { settings } = useSettings();
  const [isProcessing, setProcessing] = useState(false);
  const [isGooglePlayApp] = useLocalStore('googlePlayApp', false);
  const {
    open, LoginRegisterModel,
  } = useLoginRegister();
  const queryClient = useQueryClient();

  const { isAuthenticated } = useUser();
  const navigate = useNavigate();
  const { quota } = useCurrentQuota(isAuthenticated);

  return (
    <BasePage>
      <section className="container-fluid jumbotron">
        <div className="container pt-3 pb-5">
          <MotionViewport>
            <div className="row flex-column align-items-center justify-content-center">
              <div className="col-md-7 col-sm-12  text-center mb-4 top-side">
                <MotionItem>
                  <h1 className="mb-3">
                    Wrzuć zdjęcie zadania i
                    <span className="text-gradient-primary"> rozwiąż to </span>
                    dzięki AI.
                  </h1>
                </MotionItem>
              </div>
              <div className="col-md-6 col-sm-12 mb-4">
                <MotionItem>
                  <div className="bottom-side">
                    <div className="w-100">
                      <Dropzone
                        onUpload={onUpload}
                        onError={onError}
                        name="pdf-file"
                        extraOnUploadProps={{
                          setProcessing,
                          quota,
                          queryClient,
                          isAuthenticated,
                          open,
                          navigate,
                        }}
                      >
                        {({ getInputProps }) => (
                          isProcessing ? (
                            <div className="text-center">
                              <b>
                                <SpinnerGrow size="sm" />
                                {' '}
                                Ładowanie...
                              </b>
                            </div>
                          ) : (
                            <div className="text-center">
                              {isMobile ? (
                                <>
                                  <b>
                                    <TablerIcon icon={IconCamera} size={40} />
                                    <br />
                                    {' '}
                                    Wrzuć zdjęcie
                                  </b>

                                  <input {...getInputProps()} />
                                </>
                              ) : (
                                <>
                                  <b>
                                    <TablerIcon icon={IconCloudUpload} size={40} />
                                    <br />
                                    {' '}
                                    Załaduj zdjęcie
                                  </b>
                                  <p>(lub przeciągnij i upuść)</p>
                                  <input {...getInputProps()} />
                                </>
                              )}
                            </div>
                          )
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-12 text-start">

                  <MotionItem>
                    <h6>
                      <img
                        style={{ width: '19px', height: '19px' }}
                        src={iconCheck}
                        alt=""
                      />
                      {' '}
                      Pierwsze
                      {' '}
                      <b>2 zadania za darmo</b>
                    </h6>
                    <h6>
                      <img
                        style={{ width: '19px', height: '19px' }}
                        src={iconCheck}
                        alt=""
                      />
                      {' '}
                      Żadnych subskrypcji
                    </h6>
                    <h6>
                      <img
                        style={{ width: '19px', height: '19px' }}
                        src={iconCheck}
                        alt=""
                      />
                      {' '}
                      Polska firma
                    </h6>
                  </MotionItem>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 text-start">

                  <MotionItem>
                    <h6>
                      <img
                        style={{ width: '19px', height: '19px' }}
                        src={iconCheck}
                        alt=""
                      />
                      {' '}
                      Ponad
                      {' '}
                      <b>14 tys. użytkowników</b>
                    </h6>
                    <h6>
                      <img
                        style={{ width: '19px', height: '19px' }}
                        src={iconCheck}
                        alt=""
                      />
                      {' '}
                      AI lepsze od ChatGPT 4o
                    </h6>
                    <h6>
                      <img
                        style={{ width: '19px', height: '19px' }}
                        src={iconCheck}
                        alt=""
                      />
                      {' '}
                      Gwarancja satysfakcji
                    </h6>
                  </MotionItem>
                </div>
              </div>
              {isAndroid && !isGooglePlayApp
                      && (
                      <div className="col-md-6 col-sm-12 text-center mt-4 top-side">
                        <MotionItem>
                          <a
                            href="https://play.google.com/store/apps/details?id=pl.rozwiazto.twa"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              style={{ maxHeight: '60px' }}
                              src={googlePlayBadge}
                              alt="Pobierz z Google Play"
                            />
                          </a>
                        </MotionItem>
                      </div>
                      )}

              <div className="col-md-6 col-sm-12 text-center mt-4 top-side">
                <MotionItem>
                  <FacebookLike />
                </MotionItem>
              </div>
            </div>
          </MotionViewport>
        </div>
      </section>
      <section className="container-fluid section blue-section">
        <div className="container py-5 text-center">
          <MotionViewport>
            <MotionItem>
              <h2 className="section-title text-white">Jak to działa?</h2>
              <span className="section-sub-title text-white">
                {settings?.SITE_NAME}
                {' '}
                działa w czterech prostych krokach
              </span>
            </MotionItem>

            <div className="row align-items-stretch">
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header text-white">
                      <TablerIcon icon={IconPhotoUp} size={40} />
                      {' '}
                      Wrzucenie zadania
                    </div>
                    <div className="feature-body text-white">
                      Ładujesz zdjęcie zadania, zostaniesz poproszony o docięcie go tak, aby
                      obrazek zawierał jedno zadanie.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header text-white">
                      <TablerIcon icon={IconCheckbox} size={40} />
                      {' '}
                      AI rozwiązuje zadanie
                    </div>
                    <div className="feature-body text-white">
                      Sztuczna inteligencja odczytuje treść zadania i podaje Ci rozwiązanie wraz z
                      krokami
                      jego
                      wykonania.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header text-white">
                      <TablerIcon icon={IconUserQuestion} size={40} />
                      {' '}
                      Pytania uzupełniające
                    </div>
                    <div className="feature-body text-white">
                      Możesz dopytywać w oknie czatu o zaproponowane rozwiązanie.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header text-white">
                      <TablerIcon icon={IconHistory} size={40} />
                      {' '}
                      Historia rozmowy
                    </div>
                    <div className="feature-body text-white">
                      {settings?.SITE_NAME}
                      {' '}
                      zapamiętuje historię rozmowy i zawsze możesz do niej wrócić.
                    </div>
                  </div>
                </MotionItem>
              </div>
            </div>
          </MotionViewport>

          <MotionViewport>
            <div className="mt-4 row align-items-stretch">
              <div className="col">
                <MotionItem>
                  <div className="feature px-0 px-sm-4 pb-0 pb-sm-4">
                    <div className="feature-header px-4 px-sm-0 text-white">
                      <TablerIcon icon={IconBrandTiktok} size={40} />
                      {' '}
                      Na smartfonie
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <TikTokEmbed
                        url="https://www.tiktok.com/@rozwiazto.pl/video/7373956692054232353"
                        width={325}
                      />
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature px-0 px-sm-4 pb-0 pb-sm-4">
                    <div className="feature-header px-4 px-sm-0 text-white">
                      <TablerIcon icon={IconBrandTiktok} size={40} />
                      {' '}
                      Na komputerze
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <TikTokEmbed
                        url="https://www.tiktok.com/@rozwiazto.pl/video/7369551816486423840"
                        width={325}
                      />
                    </div>
                  </div>
                </MotionItem>
              </div>
            </div>

          </MotionViewport>
        </div>
      </section>

      <section className="container-fluid section">
        <div className="container py-5 text-center">
          <MotionViewport>
            <h2 className="section-title">Dlaczego warto?</h2>
            <span className="section-sub-title">
              {settings?.SITE_NAME}
              {' '}
              to przydatne narzędzie dla każdego ucznia i studenta.
            </span>

            <div className="row align-items-stretch">
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconCoinOff} size={40} />
                      {' '}
                      Darmowy pakiet na start
                    </div>
                    <div className="feature-body">
                      Na start dostajesz darmowy pakiet, dzięki któremu możesz sprawdzić jak działa
                      narzędzie.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconBrain} size={40} />
                      {' '}
                      Wysoka skuteczność
                    </div>
                    <div className="feature-body">
                      Aplikacja opiera się o najnowsze modele rozumującego AI w celu odczytania i poprawnego
                      rozwiązania zadania.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconDevices} size={40} />
                      {' '}
                      Wygoda użytkowania
                    </div>
                    <div className="feature-body">
                      {settings?.SITE_NAME}
                      {' '}
                      działa na smartfonie i komputerze.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconClockBolt} size={40} />
                      {' '}
                      Szybkość działania
                    </div>
                    <div className="feature-body">
                      Cały proces od przesłania zadania do uzyskania odpowiedzi zajmie sekundy.
                    </div>
                  </div>
                </MotionItem>
              </div>
            </div>
          </MotionViewport>
        </div>
      </section>

      <LoginRegisterModel
        title="Zaloguj się aby kontynuować"
        onLoginRedirectTo="/playground"
        isGoogleInitiated={googleAuthInitialized}
      />
    </BasePage>
  );
}
