import { useFormik } from 'formik';
import { memo, useCallback, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import SuperButton from '@components/SuperButton';
import { useAuth } from '@hooks/auth';
import { toastFormikErrors } from '@utils';
import { FBLoginButton } from '@components/FBLoginButton';

export default memo(({
  onLogin, setCurrentForm, onLoginRedirectTo, isGoogleInitiated,
}) => {
  const { Authenticate } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = onLoginRedirectTo || searchParams.get('to');

  useEffect(() => {
    if (isGoogleInitiated && window.google?.accounts?.id) {
      /* global google */
      google.accounts.id.renderButton(document.getElementById('signInDivModal'), {
        type: 'standard',
        shape: 'pill',
        text: 'signup_with',
        theme: 'outline',
        size: 'large',
        width: '320',
      });
    }
  }, [isGoogleInitiated]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Niewłaściwy adres email.').required('Email wymagany.'),
      password: Yup.string().required('Hasło wymagane.'),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      Authenticate(values.email, values.password).then((data) => {
        if (!data.errors) {
          toast.success(data.message);
          if (typeof onLogin === 'function') onLogin(values.email, setCurrentForm);

          if (redirectTo) return navigate(redirectTo);
        } else toast.error(data.message);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  const onClickSignIn = useCallback(() => {
    toastFormikErrors(formik.errors);
  }, []);

  return (
    <div>
      <div className="mb-3 d-flex justify-content-center">
        <div id="signInDivModal" />
      </div>
      <div className="mb-3 d-flex justify-content-center">
        <FBLoginButton />
      </div>
      <h5>Lub podaj dane:</h5>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email:</label>
          <input type="text" {...formik.getFieldProps('email')} id="email" className="form-control" />
        </div>
        <div className="mb-3">
          <div>
            <label htmlFor="password" className="form-label">Hasło:</label>
            <Link onClick={() => setCurrentForm('FORGOT_PASSWORD')} className="float-end">
              Zapomniałeś
              hasła?
            </Link>
          </div>
          <input type="password" {...formik.getFieldProps('password')} id="password" className="form-control" />
        </div>
        <SuperButton
          isLoading={formik.isSubmitting}
          onClick={onClickSignIn}
          className="btn btn-primary btn-block my-4"
          type="submit"
          disabled={!formik.isValid}
        >
          Zaloguj
        </SuperButton>
      </form>
    </div>
  );
});
